.wrapper {
  .input {
    padding: 0.625rem 0.875rem;
          margin-top: var(--mantine-spacing-s1);
          border: 1px solid var(--mantine-color-gray-3);
          color: var(--mantine-color-gray-5);
          font-size: var(--mantine-font-size-textMd);
          font-weight: var(--mantine-fong-weight-regular);
          line-height: var(--mantine-line-height-textMd);
          box-shadow: var(--mantine-shadow-xs);
          height: auto;
          
    &:focus,
    &:hover {
      border-color: var(--mantine-color-blue-6);
    }
  }
}

